import { NgModule             } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent, CountdownComponent, LocationPageComponent, BasicPageComponent } from '@shared/components';

export const routes: Routes = [
  { path: '', component: CountdownComponent },
  { path: 'andriy/super/secret/top', children: [
    { path: 'home',          component: HomeComponent         },
    { path: 'page/:useCase', component: BasicPageComponent    },
    { path: 'location',      component: LocationPageComponent },
  ]},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
