import { trigger, transition, style, animate } from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
  transition('left-void => left', [
    style({                          opacity: 0,    right: '75%', transform: 'scale(0.6)' }),
    animate('500ms ease-in', style({ opacity: 0.25, right: '30%', transform: 'scale(0.9)' }))
  ]),
  transition('left => center', [
    style({                          opacity: 0.25, right: '30%', transform: 'scale(0.9)' }),
    animate('500ms ease-in', style({ opacity: 1,    right: '30%', transform: 'scale(1) translate(-50%, 0px)', left: '50%' }))
  ]),
  transition('center => right', [
    style({ position: 'absolute',    opacity: 1,    left: '50%',  transform: 'scale(1) translate(-50%, 0px)'  }),
    animate('500ms ease-in', style({ opacity: 0.25, left: '30%',  transform: 'scale(0.9)' }))
  ]),
    transition('right => right-void', [
    style({                          opacity: 0.25, left: '30%',  transform: 'scale(0.9)' }),
    animate('500ms ease-in', style({ opacity: 0,    left: '75%',  transform: 'scale(0.6)' }))
  ]),

  transition('right-void => right', [
    style({                          opacity: 0,    left: '75%',  transform: 'scale(0.9)' }),
    animate('500ms ease-in', style({ opacity: 0.25, left: '30%',  transform: 'scale(0.9)' }))
  ]),
  transition('right => center', [
    style({                          opacity: 0.25, left: '30%',  transform: 'scale(0.9)' }),
    animate('500ms ease-in', style({ opacity: 1,    left: '50%',  transform: 'scale(1) translate(-50%, 0px)'}))
  ]),
  transition('center => left', [
    style({ position: 'absolute',    opacity: 1,    right: '50%',  transform: 'scale(1) translate(50%, 0px)' }),
    animate('500ms ease-in', style({ opacity: 0.25, right: '30%',  transform: 'scale(0.9)' }))
  ]),
    transition('left => left-void', [
    style({                          opacity: 0.25, right: '30%',  transform: 'scale(0.9)' }),
    animate('500ms ease-in', style({ opacity: 0,    right: '75%',  transform: 'scale(0.6)' }))
  ])
]);
