<div class="utd-flex-column gap-s">
  <span class="font-30">Події</span>
  <div class="utd-flex-column gap-xs font-l">
    <span>Весілля</span>
    <span>Ювілеї</span>
    <span>Дні народження</span>
    <span>Церемонії під відкритим небом</span>
    <span>Корпоративи</span>
    <span>Конференціії</span>
    <span>Зустрічі</span>
  </div>
</div>

<div class="utd-flex-column gap-s">
  <span class="font-30">Локації</span>
  <div class="utd-flex-column gap-xs font-l">
    <span>ресторан 1</span>
    <span>Ресторан 2</span>
    <span>Виноградник</span>
    <span>Івент-локація</span>
  </div>
</div>

<div class="utd-flex-column gap-s">
  <span class="font-30">Загальна інформація</span>
  <div class="utd-flex-column gap-xs font-l">
    <span>Про нас</span>
    <span>Розміщення</span>
    <span>їжа та напої</span>
    <span>Замовлення</span>
    <span>Контакти</span>
  </div>
</div>
