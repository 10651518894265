import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { LocationPageComponent } from './components/location-page/location-page.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BookLocationComponent } from './shared/book-location/book-location.component';
import { MenuBlockComponent } from './shared/menu-block/menu-block.component';
import { CallButtonComponent } from './shared/call-button/call-button.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BasicPageComponent } from './components/basic-page/basic-page.component';
import { SessionStorageService } from './services/session-storage.service';
import { PictureSliderComponent } from './shared/picture-slider/picture-slider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CountdownComponent,
    LocationPageComponent,
    HeaderComponent,
    FooterComponent,
    BookLocationComponent,
    MenuBlockComponent,
    SidebarComponent,
    BasicPageComponent,
    CallButtonComponent,
    PictureSliderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SessionStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
