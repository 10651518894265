import { Component } from '@angular/core';

@Component({
  selector: 'main-footer',
  templateUrl: './footer.component.html',
  host: { class: 'utd-flex-column gap-l bg-black color-white p-xl' }
})
export class FooterComponent {

}
