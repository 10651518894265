import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-block',
  templateUrl: './menu-block.component.html',
  host: { class: 'utd-flex-column gap-m p-xl bg-grey color-black' }
})
export class MenuBlockComponent implements OnInit {
  title: string;
  elements: any[];

  ngOnInit() {
    this.title = 'Зимове меню';
    this.elements = [
      { price: 240, label: 'Закуски з кухні' },
      { price: 240, label: 'Закуски з кітцю Альбакор на грилі, фава, зелені оливки песто брюссельська капуста, зелений перець чилі, гарбузове насіння топінамбур, цвітна капуста, айва агродолче курка' },
      { price: 240, label: 'Конкільє, палена морква, мигдаль, циме ді рапа Додати трюфелі Red Hill 15 доларів за порцію' },
      { price: 240, label: 'Грудка деревна копчена, гарбуз, хрін Садове листя, грейпфрут, грейпфрутовий соусГрудка деревна копчена, гарбуз, хрін Садове листя, грейпфрут, грейпфрутовий соус' },
      { price: 240, label: 'Конкільє, палена морква, мигдаль, циме ді рапа Додати трюфелі Red Hill 15 доларів за порцію' },
      { price: 240, label: 'Закуски з кухні' },
    ];
  }
}
