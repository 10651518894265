import { Injectable      } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private callPulseSource = new BehaviorSubject<boolean>(null);
  callPulse = this.callPulseSource.asObservable();
  constructor () { }

  changeCallPulse(state: boolean): void {
    this.callPulseSource.next(state);
  }

}
