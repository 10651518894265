import { trigger, transition, style, animate } from '@angular/animations';

export const fadeBg = trigger('fadeBg', [
  transition(':leave', [
    style({              opacity: 1 }),
    animate(200, style({ opacity: 0 }))
    
  ], { params: { offsetTop: '0' }}), 
  transition(':enter', [
    style({              opacity: 0 }),
    animate(200, style({ opacity: 1 }))
  ])
]);