import { Component } from '@angular/core';
import { faBars, IconDefinition } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  host: { class: 'utd-flex-align utd-flex-justify p-s' }
})
export class HeaderComponent {
  faBars: IconDefinition = faBars;

  toggleSidebar(): void {
    let sidebar = document.getElementsByClassName('translate-left-full')[0];
    sidebar.classList.toggle('active');
  }

}
