<span class="font-xl uppercase">{{ title }}</span>
<ul class="utd-flex-column gap-xs" *ngIf="elements && elements.length">
  <li class="utd-flex-column gap-xs" *ngFor="let el of elements">
    <span class="utd-flex-justify utd-flex-align-end gap-xl">
      <span class="font-l">{{ el.label }}</span>
      <span class="font-xl">{{ el.price }}грн.</span>
    </span>
    <span class="width-90 bb-grey"></span>
  </li>
</ul>

<div class="utd-flex gap-s">
  <span class="utd-flex-1 bb-grey"></span>
  <button class="primary grey">Завантажити меню</button>
  <button class="secondary grey">Дізнатись більше</button>
</div>