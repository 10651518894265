<ul class="utd-flex-column">
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-1.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="font-40 absolute top-0 left-0 ml-xl mt-xl">Події</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-2.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="font-40 absolute top-0 left-0 ml-xl mt-xl">Локації</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-3.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="color-white font-40 absolute top-0 left-0 ml-xl mt-xl">Ресторан</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-4.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="color-white font-40 absolute top-0 left-0 ml-xl mt-xl">Пікніки</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-5.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="color-white font-40 absolute top-0 left-0 ml-xl mt-xl">ЕкоПродукти</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
  <li class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-6.JPG)'" [routerLink]="['/andriy/super/secret/top/location']">
    <span class="font-40 absolute top-0 left-0 ml-xl mt-xl">Весілля</span>
    <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Дізнатись більше</button>
  </li>
</ul>