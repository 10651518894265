import { Component, Input, OnInit } from '@angular/core';
import { fadeBg } from '@shared/animations';

@Component({
  selector:      'picture-slider',
  templateUrl: './picture-slider.component.html',
  host: { class: 'utd-flex-column height-70vh-min relative' },
  animations: [fadeBg]
})
export class PictureSliderComponent implements OnInit {
  @Input() pictures: string[] = [];
  activePicture: string;
  hide:          boolean;
  constructor() {}

  ngOnInit(): void {
    this.activePicture = this.pictures[0];
  }

  getBgImage(url: string): string {
    return url ? `url(${url})` : '';
  }

  changePicture(picture: string): void {
    if (this.activePicture !== picture) {
      this.hide = true;
      setTimeout(() => {
        this.activePicture = picture;
        this.hide = false;
      }, 200);
    }
  }

}
