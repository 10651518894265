import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { fadeIn } from '@shared/animations';

interface LocationModel {
  id:       number;
  name:     string;
  imageSrc: string;
  state?:    string;
  extra:    LocationExtraModel;
}

interface LocationExtraModel {
  scheduleStart: string;
  scheduleEnd:   string;
  visitorsMin:   number;
  visitorsMax:   number;
}

@Component({
  selector:      'book-location',
  templateUrl: './book-location.component.html',
  host: { class: 'utd-flex-column gap-m utd-flex-align font-l' },
  animations: [fadeIn]
})
export class BookLocationComponent implements OnInit {
  faChevronLeft:  IconDefinition  = faChevronLeft;
  faChevronRight: IconDefinition  = faChevronRight;

  count: number = 2;

  sliders:        LocationModel[];
  activeIndex:    number = 0;
  animation: boolean;
  
  locations:      LocationModel[] = [
    {
      id:       1,
      name:     'Поляна під дубом',
      imageSrc: 'assets/images/block-3.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    }, {
      id:       1,
      name:     'Поляна під вербою',
      imageSrc: 'assets/images/block-1.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    }, {
      id:       1,
      name:     'Поляна під тополею',
      imageSrc: 'assets/images/block-4.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    }, {
      id:       1,
      name:     'Поляна під поляною',
      imageSrc: 'assets/images/block-2.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    },{
      id:       1,
      name:     'Поляна під грибами',
      imageSrc: 'assets/images/block-1.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    },{
      id:       1,
      name:     'Поляна під пиво',
      imageSrc: 'assets/images/block-4.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    },{
      id:       1,
      name:     'Поляна під від',
      imageSrc: 'assets/images/block-3.JPG',
      extra:    {
        scheduleStart: '10:00',
        scheduleEnd:   '20:00',
        visitorsMin: 2,
        visitorsMax: 10
      }
    },
  ];
  constructor() {}

  ngOnInit(): void {
    this.prepareSlider();
  }

  get activeLocation(): LocationModel {
    return this.locations[(this.activeIndex+7)%this.locations.length];
  }

  private prepareSlider(): void {
    this.sliders = [];
    for (let i = this.activeIndex; i < this.activeIndex+5; i++) {
      this.sliders.push(this.locations[i%7]);
    }
    this.resetSliderStates();
  }

  goNext() {
    this.activeIndex++;
    this.activeIndex = (this.activeIndex+7) % 7;
    this.moveSlidesStateRight();
  }

  goPrev() {
    this.activeIndex--;
    this.activeIndex = (this.activeIndex+7) % 7;
    this.moveSlidesStateLeft();
  }

  animationStart(e: any) {
    if (e.fromState !== 'void' && !this.animation) this.animation = true;
  }

  animationDone(e: any) {
    console.log(this.animation)
    if (this.animation) {
      this.prepareSlider();
      this.animation = false
    }
  }

  private resetSliderStates() {
    this.sliders[0].state = 'left-void';
    this.sliders[1].state = 'left';
    this.sliders[2].state = 'center';
    this.sliders[3].state = 'right';
    this.sliders[4].state = 'right-void';
  }

  private moveSlidesStateLeft() {
    this.sliders[0].state = 'left';
    this.sliders[1].state = 'center';
    this.sliders[2].state = 'right';
    this.sliders[3].state = 'right-void';
    this.sliders[4].state = 'right-void';
  }

  private moveSlidesStateRight() {
    this.sliders[0].state = 'left-void';
    this.sliders[1].state = 'left-void';
    this.sliders[2].state = 'left';
    this.sliders[3].state = 'center';
    this.sliders[4].state = 'right';
  }

}
