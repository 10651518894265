import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector:      'sidebar',
  templateUrl: './sidebar.component.html',
  host: { class: 'utd-flex-column gap-xl absolute-full bg-white pt-s pl-xl pr-xl pb-xl color-black translate-left-full z-5'}
})
export class SidebarComponent implements OnInit {
  items: any = [
    { label: 'Пікніки',       path: '/andriy/super/secret/top/page/picnic'     },
    { label: 'Фотосесії',     path: '/andriy/super/secret/top/page/location'     },
    { label: 'Озера',         path: '/andriy/super/secret/top/page/lakes'        },
    { label: 'Апартсервіс',   path: '/andriy/super/secret/top/page/apartservice' },
    { label: 'Магазин',       path: '/andriy/super/secret/top/page/shop'         },
    { label: 'Партнери',      path: '/andriy/super/secret/top/page/partners'     },
    { label: 'Акції',         path: '/andriy/super/secret/top/page/sale'         },
    { label: 'Календар Жнив', path: '/andriy/super/secret/top/page/calendar'     },
    { label: 'Події',         path: '/andriy/super/secret/top/page/events'       },
    { label: 'Виноград',      path: '/andriy/super/secret/top/page/grapes'       },
    { label: 'Дрони',         path: '/andriy/super/secret/top/page/drones'       },
    { label: 'Контакти',      path: '/andriy/super/secret/top/page/contact'      },
    { label: 'Food Сервіси',  path: '/andriy/super/secret/top/page/food-service' },
    { label: 'Йога',          path: '/andriy/super/secret/top/page/yoga'         },
    { label: 'Retreat',       path: '/andriy/super/secret/top/page/retreat'      },
    { label: 'Донат ЗСУ',     path: '/andriy/super/secret/top/page/donation'     },
  ]
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
    // setTimeout(() => {
    //   this.toggleSidebar()
    // }, 2000)
  }

  toggleSidebar(): void {
    let sidebar = document.getElementsByClassName('translate-left-full')[0];
    sidebar.classList.toggle('active');
  }

  openLink(link: string): void {
    this.router.navigateByUrl(link);
    this.toggleSidebar();
  }

}
