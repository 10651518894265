import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionStorageService } from './services/session-storage.service';

@Component({
  selector:      'app-root',
  templateUrl: './app.component.html',
  host: { class: 'utd-flex-column height-full' }
})
export class AppComponent implements OnInit {
  secret: boolean;
  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
     this.secret = event.url.includes('andriy/super/secret/top');
    });
    this.sessionStorageService.changeCallPulse(false);
    setTimeout(() => this.sessionStorageService.changeCallPulse(true), 15000);
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight-574) {
      setTimeout(() => this.sessionStorageService.changeCallPulse(true), 1000);
    }
  }
}
