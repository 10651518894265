export const picnicConfig = {
  title: 'Пікніки',
  layout: [
    {
      image: 'assets/images/block-1.JPG',
      title: 'Пікніки',
      text: '7 днів, 11:00 - 17:00',
      buttons: [{
        class: 'primary white',
        label: 'Забронювати',
        routerLink: ''
      }]
    },
    {
      title: 'Ласкаво просимо,',
      text: `до нашої чудової оази для відпочинку та пікніків!

             Забудьте про гамір міського життя і віддайтеся природі. Наша головна сторінка - це ключ до найкращих моментів спільного відпочинку з друзями та родиною.

             Природа завжди відкрита для тих, хто цінує її красу. Забронюйте своє місце для пікніку просто зараз і готуйтесь до днів, наповнених радістю та відпочинком серед природи.

             Приєднуйтесь до нас та створіть свої власні пригоди під відкритим небом. Насолоджуйтеся природою, смачними стравами та відмінною компанією!

             Чекаємо на вас, щоб разом створити незабутні моменти.`,
      buttons: [
        { class: 'primary black',   label: 'Забронювати', routerLink: '' },
        { class: 'secondary black', label: 'Подарувати',  routerLink: '' }
      ]
    },
    { image: 'assets/images/block-2.JPG' },
    {
      title: 'Зарезервувати пікнік',
      text: `можна в будь-який зручний для вас час! Ми розуміємо, що різні люди мають різні графіки та плани, тому ми пропонуємо гнучкий графік бронювання. Ось деталі щодо бронювання:

             Дні тижня: Ми приймаємо бронювання на будь-який день тижня, включаючи вихідні та святкові дні.

             Тривалість бронювання: Ви можете зарезервувати пікнік-зону на половину дня (4 години) або на повний день (8 годин).

             Заздалегідь: Рекомендуємо бронювати місце заздалегідь, особливо якщо ви плануєте приїзд у популярний час або великою групою. Бронювання заздалегідь дає вам гарантований доступ до пікнік-зони у вибраний вами час.

             Онлайн бронювання: Ви можете зарезервувати місце для пікніку онлайн на нашому веб-сайті або за допомогою нашого додатку. Просто оберіть дату, час та кількість осіб, і ми піклуватимемося про решту.

             Телефон бронювання: Якщо вам зручніше зателефонувати, наші оператори завжди готові вас обслуговувати та допомогти з бронюванням.

             Ми стараємося зробити процес бронювання максимально зручним і доступним для наших клієнтів, щоб ви могли насолоджуватися своїм пікніком у зручний для вас час. Не зволікайте, і зарезервуйте своє місце вже сьогодні, щоб мати впевненість у відмінному відпочинку серед природи!`,
      buttons: [
        { class: 'secondary black', label: 'Дізнатись більше',  routerLink: '' }
      ]
    },
    { image: 'assets/images/block-3.JPG' },
    {
      text: `Завітайте на наші пікнік-зони та відкрийте для себе чудовий світ відпочинку. Ось деталі щодо цін, кількості місць і часу:

             Ціни:

             Дорослий (13 років і старше): $20 за особу.

             Діти (6-12 років): $10 за особу.

             Діти до 6 років: Безкоштовно.

             Групові знижки доступні для груп від 10 осіб - зв\'яжіться з нами для отримання інформації.

             Кількість місць:

             Ми маємо різні розміри пікнік-зон, щоб відповісти вашим потребам. Максимальна кількість осіб на пікнік-зоні становить 10-12 осіб, в залежності від її розміру.

             Час:

             Пікнік можна забронювати на такий період:

             Половина дня (4 години): Зранку (9:00 - 13:00) або ввечері (14:00 - 18:00).

             Повний день (8 годин): Від 9:00 до 17:00.

             Не забудьте забронювати своє місце заздалегідь, оскільки популярні дати можуть бути зайняті. Наша команда завжди готова вас обслуговувати та забезпечити незабутній день пікніку!

             Зарезервуйте своє місце вже сьогодні і підготуйтеся до відмінного відпочинку серед природи.`,
      buttons: [
        { class: 'primary black',   label: 'Забронювати', routerLink: '' },
        { class: 'secondary black', label: 'Подарувати',  routerLink: '' }
      ]
    },
    { image: 'assets/images/block-4.JPG' },
    {
      title: 'Шановні гості,',
      text: `Ми цінуємо вашу участь у нашому пікніку і готові забезпечити вам найкращий відпочинок на свіжому повітрі. Однак ми повинні бути готові до непередбачених обставин, таких як погодні умови та повітряна тривога, які можуть вплинути на наш захід.

             Погодні умови: Хоча ми плануємо провести пікнік незалежно від погоди, інколи погодні умови можуть бути небезпечними. У разі сильного дощу, грози, снігопаду чи інших небезпечних метеорологічних умов, ми можемо бути змушені скасувати захід. Ми вас інформуємо про такі скасування якомога раніше і пропонуємо альтернативні дати або повернення коштів.

             Повітряна тривога: Наша головна мета - це ваша безпека. Якщо буде оголошено повітряну тривогу або надходять повідомлення про небезпечні умови, ми можемо прийняти рішення про скасування пікніку. Ваша безпека завжди стоїть на першому місці.

             Будь ласка, слідкуйте за сповіщеннями та оновленнями від нас щодо вашого пікніку. Ми робимо все можливе, щоб забезпечити вам найкращий досвід, і сподіваємося на ваше розуміння в разі потреби в скасуванні заходу з метою збереження вашої безпеки.

             Дякуємо за ваше обрання і за довіру до нас. Незважаючи на обставини, ми завжди готові зробити ваш відпочинок найкращим!`,
      buttons: [
        { class: 'secondary black', label: 'Дізнатись більше',  routerLink: '' }
      ]
    }
  ]
};