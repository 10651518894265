<div *ngIf="activePicture"
      class="utd-flex-1 bg-image opacity-animate shadow"
     [style.background-image]="getBgImage(activePicture)"
     [style.opacity]="+!hide"
     >
</div>

<div class="absolute-bottom-center bottom-xl utd-flex-center gap-xs">
  <span *ngFor="let dot of pictures" (click)="changePicture(dot)"
         class="circle-xl hover-active bg-animate"
        [class.b-white]="activePicture  !== dot"
        [class.bg-white]="activePicture === dot"></span>
</div>