export const cabinsConfig = {
  title: 'Котеджі у Green Prostir',
  layout: [
    {
      image: 'assets/images/block-1.JPG',
      title: 'Котеджі у Green Prostir',
    },
    {
      title: 'Наші котеджі -',
      text: `це оазис затишку та комфорту, розташований серед непорушеної природи. Тут ви зможете відчути справжній відпочинок від міської метушні та віддатися насолоді еко-атмосферою нашого саду.`,
    },
    { pictures: [
      'assets/images/block-1.JPG',
      'assets/images/block-2.JPG',
      'assets/images/block-3.JPG',
      'assets/images/block-4.JPG',
    ]},
    { text: `Додаткові послуги: Якщо є, опис можливих додаткових послуг. Додаткові послуги: Якщо є, опис можливих додаткових послуг. Додаткові послуги: Якщо є, опис можливих додаткових послуг. Додаткові послуги: Якщо є, опис можливих додаткових послуг.` },
    { image: 'assets/images/block-3.JPG' },
    { text: `Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше` },
    { image: 'assets/images/block-4.JPG' },
    { text: `Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше Опис кожного котеджу: Детальна інформація про кожен котедж, включаючи кількість спальних місць, зручності  та інше` },
    { bookForm: true},
    { text: `Ціни та умови: Інформація про вартість проживання в кожному котеджі, режим роботи, умови бронювання та скасування. Ціни та умови: Інформація про вартість проживання в кожному котеджі, режим роботи, умови бронювання та скасування. Ціни та умови: Інформація про вартість проживання в кожному котеджі, режим роботи, умови бронювання та скасування.` },
    { image: 'assets/images/block-2.JPG' },
  ]
};