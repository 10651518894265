<span class="font-xl pt-xl uppercase">Забронювати</span>

<div class="utd-flex-align-end gap-xl width-300"  >
  <fa-icon class="width-40 text-center font-xl" [icon]="faChevronLeft" (click)="goPrev()" ></fa-icon>
  <span class="utd-flex-column utd-flex-align gap-xxs">
    <span class="font-s">Локація</span>
    <span class="font-xl">{{ activeLocation.name }}</span>
  </span>
  <fa-icon class="width-40 text-center font-xl" [icon]="faChevronRight" (click)="goNext()"></fa-icon>
</div>

<ul class="slider-wrapper height-150 width-full pl-xl pr-xl overflow">
  <li *ngFor="let slider of sliders" class="b-radius height-full width-250 bg-image shadow"
      [@fadeIn]="slider.state" (@fadeIn.done)="animationDone($event)" (@fadeIn.start)="animationStart($event)"
      [style.background-image]="'url(' + slider.imageSrc + ')'">
  </li>
</ul>

<button class="secondary black">Детальніше про локацію</button>
<div class="width-full bb-grey"></div>

<div class="utd-flex-center width-300">
  <fa-icon class="width-40 text-center font-xl" [icon]="faChevronLeft"  (click)="count = count-1 || 1" ></fa-icon>
  <div class="utd-flex-column utd-flex-center utd-flex-1 gap-xs">
    <span class="font-s">кількість гостей:</span>
    <span class="font-xl">{{ count }} {{ count === 1 ? 'Гість' : count > 4 ? 'Гостей' : 'Гостя' }}</span>
  </div>
  <fa-icon class="width-40 text-center font-xl" [icon]="faChevronRight" (click)="count = count+1"></fa-icon>
</div>
<div class="width-full bb-grey"></div>

<div class="utd-flex-center gap-xl width-300">
  <input type="date" class="date-input font-20">
  <input type="date" class="date-input font-20">
</div>

<button class="primary black mt-s">Забронювати</button>


