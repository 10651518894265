<div class="utd-flex-justify bb-grey">
  <span class="uppercase font-50">GARDEN PROSTIR</span>
  <img src="assets/svg/exit-black.svg" alt="" (click)="toggleSidebar()">
</div>

<ul class="utd-flex-column utd-flex-1 gap-m overflow-auto">
  <li class="uppercase font-30" *ngFor="let item of items" (click)="openLink(item.path)">{{item.label}}</li>
</ul>

<div class="utd-flex-align utd-flex-justify-end gap-xl">
  <img src="assets/svg/facebook-black.svg" alt="">
  <img src="assets/svg/twitter-black.svg" alt="">
  <img src="assets/svg/instagram-black.svg" alt="">
</div>
