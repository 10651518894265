<menu-block></menu-block>
<div class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-1.JPG)'">
  <span class="utd-flex-column gap-s absolute top-0 left-0 ml-xl mt-xl ">
    <span class="font-40">Пікніки</span>
    <span class="font-20">7 днів, 11:00 - 17:00</span>
  </span>
  <button class="secondary white absolute bottom-0 right-0 mr-xl mb-xl">Забронювати</button>
</div>

<div class="utd-flex-column gap-s m-xl pr-xl br-grey font-20">
  <span class="utd-flex-column gap-xxs">
    <span class="font-30">Ласкаво просимо,</span>
    <span>до нашої чудової оази для відпочинку та пікніків!</span>
  </span>

  <span>Забудьте про гамір міського життя і віддайтеся природі. Наша головна сторінка - це ключ до найкращих моментів спільного відпочинку з друзями та родиною.</span>

  <span>Природа завжди відкрита для тих, хто цінує її красу. Забронюйте своє місце для пікніку просто зараз і готуйтесь до днів, наповнених радістю та відпочинком серед природи.</span>
  <span>Приєднуйтесь до нас та створіть свої власні пригоди під відкритим небом. Насолоджуйтеся природою, смачними стравами та відмінною компанією!</span>
  <span>Чекаємо на вас, щоб разом створити незабутні моменти.</span>
</div>

<div class="utd-flex gap-s p-xl">
  <span class="utd-flex-1 bb-grey"></span>
  <button class="primary black">Забронювати</button>
  <button class="secondary black">Подарувати</button>
</div>

<div class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-2.JPG)'"></div>

<div class="utd-flex-column gap-s m-xl pr-xl br-grey font-20">
  <span class="utd-flex-column gap-xxs">
    <span class="font-30">Зарезервувати пікнік</span>
    <span>можна в будь-який зручний для вас час! Ми розуміємо, що різні люди мають різні графіки та плани, тому ми пропонуємо гнучкий графік бронювання. Ось деталі щодо бронювання:</span>
  </span>
  <span>Дні тижня: Ми приймаємо бронювання на будь-який день тижня, включаючи вихідні та святкові дні.</span>
  <span>Тривалість бронювання: Ви можете зарезервувати пікнік-зону на половину дня (4 години) або на повний день (8 годин).</span>
  <span>Заздалегідь: Рекомендуємо бронювати місце заздалегідь, особливо якщо ви плануєте приїзд у популярний час або великою групою. Бронювання заздалегідь дає вам гарантований доступ до пікнік-зони у вибраний вами час.</span>
  <span>Онлайн бронювання: Ви можете зарезервувати місце для пікніку онлайн на нашому веб-сайті або за допомогою нашого додатку. Просто оберіть дату, час та кількість осіб, і ми піклуватимемося про решту.</span>
  <span>Телефон бронювання: Якщо вам зручніше зателефонувати, наші оператори завжди готові вас обслуговувати та допомогти з бронюванням.</span>
  <span>Ми стараємося зробити процес бронювання максимально зручним і доступним для наших клієнтів, щоб ви могли насолоджуватися своїм пікніком у зручний для вас час. Не зволікайте, і зарезервуйте своє місце вже сьогодні, щоб мати впевненість у відмінному відпочинку серед природи!</span>
</div>

<div class="utd-flex gap-s p-xl">
  <span class="utd-flex-1 bb-grey"></span>
  <button class="secondary black">Дізнатись більше</button>
</div>

<div class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-3.JPG)'"></div>
 
<div class="utd-flex-column gap-s m-xl pr-xl br-grey font-20">
  <span>Завітайте на наші пікнік-зони та відкрийте для себе чудовий світ відпочинку. Ось деталі щодо цін, кількості місць і часу:</span>
  <span>Ціни:</span>
  <span>Дорослий (13 років і старше): $20 за особу.</span>
  <span>Діти (6-12 років): $10 за особу.</span>
  <span>Діти до 6 років: Безкоштовно.</span>
  <span>Групові знижки доступні для груп від 10 осіб - зв'яжіться з нами для отримання інформації.</span>
  <span>Кількість місць:</span>
  <span>Ми маємо різні розміри пікнік-зон, щоб відповісти вашим потребам. Максимальна кількість осіб на пікнік-зоні становить 10-12 осіб, в залежності від її розміру.</span>
  <span>Час:</span>
  <span>Пікнік можна забронювати на такий період:</span>
  <span>Половина дня (4 години): Зранку (9:00 - 13:00) або ввечері (14:00 - 18:00).</span>
  <span>Повний день (8 годин): Від 9:00 до 17:00.</span>
  <span>Не забудьте забронювати своє місце заздалегідь, оскільки популярні дати можуть бути зайняті. Наша команда завжди готова вас обслуговувати та забезпечити незабутній день пікніку!</span>
  <span>Зарезервуйте своє місце вже сьогодні і підготуйтеся до відмінного відпочинку серед природи.</span>
</div>
<div class="utd-flex gap-s p-xl">
  <span class="utd-flex-1 bb-grey"></span>
  <button class="primary black">Забронювати</button>
  <button class="secondary black">Подарувати</button>
</div>

<div class="relative height-70vh-min shadow bg-image" [style.background-image]="'url(assets/images/block-4.JPG)'"></div>

<div class="utd-flex-column gap-s m-xl pr-xl br-grey font-20">
  <span class="font-30">Шановні гості,</span>
  <span>Ми цінуємо вашу участь у нашому пікніку і готові забезпечити вам найкращий відпочинок на свіжому повітрі. Однак ми повинні бути готові до непередбачених обставин, таких як погодні умови та повітряна тривога, які можуть вплинути на наш захід.</span>
  <span>Погодні умови: Хоча ми плануємо провести пікнік незалежно від погоди, інколи погодні умови можуть бути небезпечними. У разі сильного дощу, грози, снігопаду чи інших небезпечних метеорологічних умов, ми можемо бути змушені скасувати захід. Ми вас інформуємо про такі скасування якомога раніше і пропонуємо альтернативні дати або повернення коштів.</span>
  <span>Повітряна тривога: Наша головна мета - це ваша безпека. Якщо буде оголошено повітряну тривогу або надходять повідомлення про небезпечні умови, ми можемо прийняти рішення про скасування пікніку. Ваша безпека завжди стоїть на першому місці.</span>
  <span>Будь ласка, слідкуйте за сповіщеннями та оновленнями від нас щодо вашого пікніку. Ми робимо все можливе, щоб забезпечити вам найкращий досвід, і сподіваємося на ваше розуміння в разі потреби в скасуванні заходу з метою збереження вашої безпеки.</span>
  <span>Дякуємо за ваше обрання і за довіру до нас. Незважаючи на обставини, ми завжди готові зробити ваш відпочинок найкращим!</span>
</div>
<div class="utd-flex gap-s p-xl">
  <span class="utd-flex-1 bb-grey"></span>
  <button class="secondary black">Дізнатись більше</button>
</div>
