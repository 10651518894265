import { Component, OnInit } from '@angular/core';
import { SessionStorageService } from 'app/services/session-storage.service';

@Component({
  selector:      'call-button',
  templateUrl: './call-button.component.html',
  host: { class: 'utd-flex-center circle-xl p-xl absolute bottom-xl right-xl bg-amber z-1', '[class.pulse]': 'pulseState' }
})
export class CallButtonComponent implements OnInit {
  pulseState: boolean;

  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.callPulse.subscribe(pulseState => this.pulseState = pulseState);
  }

}
