import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute    } from '@angular/router';
import { Title             } from "@angular/platform-browser";
import { take              } from 'rxjs';

import * as configs from './pages/index';

@Component({
  selector: 'app-page',
  templateUrl: './basic-page.component.html'
})
export class BasicPageComponent implements OnInit {
  layout:  any[];
  configs: any = configs;
  constructor(
    private route:        ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(
      params => {
        let config = this.configs[`${params['useCase']}Config`];
        this.titleService.setTitle(config.title);
        this.layout = config.layout;
      }
    );
  }

  getBgImage(url: string): string {
    return url ? `url(${url})` : '';
  }

}
