<div *ngFor="let block of layout" class="utd-flex-column gap-xl"
     [ngClass]="block.class"
     [class.p-xl]="!block.pictures"
     [class.shadow]="block.image"
     [class.bg-image]="block.image"
     [class.height-70vh-min]="block.image || block.pictures"
     [style.background-image]="getBgImage(block.image)">
  <div *ngIf="!block.pictures" class="utd-flex-1 utd-flex-column pr-m" [class.br-grey]="!block.image && block.text">
    <span *ngIf="block.title" class="font-40">{{ block.title }}</span>
    <span *ngIf="block.text"  class="font-20 word-pre-line">{{ block.text }}</span>
  </div>
  <div *ngIf="block.text" class="utd-flex-align-end gap-m">
    <div class="utd-flex-1" [class.bb-grey]="!block.image && !block.pictures"></div>
    <div class="utd-flex-align gap-xs">
      <button *ngFor="let button of block.buttons" [ngClass]="button.class" [routerLink]="button.routerLink">{{ button.label }}</button>
    </div>
  </div>
  <picture-slider *ngIf="block.pictures" [pictures]="block.pictures"></picture-slider>
  <book-location  *ngIf="block.bookForm"></book-location>
  
</div>
