import { Component, OnInit } from '@angular/core';
import { faTiktok, faInstagram, IconDefinition } from '@fortawesome/free-brands-svg-icons'
@Component({
  selector:      'countdown',
  templateUrl: './countdown.component.html',
  host: { class: 'utd-flex-column utd-flex-center height-full gap-xs p-xl bg-dark-grey' }
})
export class CountdownComponent implements OnInit {
  faTiktok:    IconDefinition = faTiktok;
  faInstagram: IconDefinition = faInstagram;

  release:   Date = new Date(2023, 10, 1, 12, 0, 0, 0);
  countdown: Date = new Date(+this.release - +new Date())

  ngOnInit(): void {
    setInterval(() => this.countdown = new Date(+this.release - +new Date()), 1000);
  }

  redirectInstagram(): void {
    this.redirect('https://www.instagram.com/garden.prostir/');
  }

  redirectTikTok(): void {
    this.redirect('https://www.tiktok.com/@garden.prostir?_t=8fPuCwUEwze');
  }

  private redirect(url: string): void {
    window.open(url, '_blank');

  }



}
