import { Component, OnInit } from '@angular/core';
import { faWindows } from '@fortawesome/free-brands-svg-icons';

@Component({
  templateUrl: './location-page.component.html',
  host: { class: 'color-grey'}
})
export class LocationPageComponent implements OnInit {
  ngOnInit() {
    let el = document.getElementsByTagName('main-header')[0];
    el.nextElementSibling.scrollTo(0, 0);
  }
}
